.container-new-sale .new-sale-title {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

.container-new-sale .products-list-container {
  text-align: center;
  border: 2px solid #e2e2e2;
  border-radius: 3px;
  margin-top: 20px;
}

.container-new-sale .quantity-title {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}

.container-new-sale .input-quantity-container {
  display: flex;
  justify-content: center;
}

.container-new-sale .input-quantity {
  padding: 10px;
  width: 100%;
  display: block;
  border: 0;
  color: #374957;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 3px;
  font-size: 16px;
  height: 38px;
  -webkit-appearance: none;
  margin-top: 20px;
  text-align: center;
}

.container-new-sale .btn {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 11px;
  text-align: center;
  font-size: 16px;
  line-height: 20px !important;
  background: #ff00a0 !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  border: 0;
  color: white;
  max-width: 500px;
  margin: 12px auto;
  display: block;
  font-weight: 600;
}

.container-new-sale .btn.disabled {
  background: #fafafa !important;
  color: #bbbbbb !important;
  cursor: not-allowed;
  pointer-events: none;
}
