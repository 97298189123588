.shift-volunteerDetail.loading,
.user-image-banner.loading {
  opacity: 0.5;
}

.user-image-banner {
  width: 100%;
  height: 150px;
  background: rgb(230, 230, 230);
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-image-icon {
  font-size: 60px;
}

.user-shift-title {
  text-align: center;
}

.card-amount span {
  margin-left: 5px;
  font-size: 0.8em;
}

.work-in-progress {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
