.setting-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.setting-container > div {
  flex: 1;
}

.logOutIcon {
  left: 0;
  position: absolute;
}
